export const settingsTranslation = {
    en: {
        "Settings": "Settings",
        "Enable Particles Background": "Enable Particles Background",
        "Update Success": "Update Success",
        "Please reload this page to see the result": "Please reload this page to see the result",
        "Type here...": "Type here...",
        "Search": "Search",
        "Particles BG": "Particles BG",
        "Language": "Language",
        "Cancle": "Cancle",
        "Apply": "Apply",
        "Close": "Close",
        "Music BG": "Music BG",
        "Volume": "Volume",
        "Play": "Play",
        "Stop": "Stop",
        "Music will start in a moment": "Music will start in a moment",
        "Music stoped": "Music stoped",
    },
    th: {
        "Settings": "การตั้งค่า",
        "Enable Particles Background": "เปิดการใช้งานพื้นหลัง Particles",
        "Update Success": "อัปเดตสำเร็จ",
        "Please reload this page to see the result": "โปรดรีโหลดหน้านี้ใหม่เพื่อดูผลลัพธ์",
        "Type here...": "ค้นหาตรงนี้ได้เลย...",
        "Search": "ค้นหา",
        "Particles BG": "พื้นหลัง Particles",
        "Language": "ภาษา",
        "Cancle": "ยกเลิก",
        "Apply": "ปรับใช้",
        "Close": "ปิด",
        "Music BG": "เพลง",
        "Volume": "ระดับเสียง",
        "Play": "เล่น",
        "Stop": "หยุด",
        "Music will start in a moment": "เพลงกำลังจะเริ่ม",
        "Music stoped": "เพลงหยุดเเล้ว",
    }
}