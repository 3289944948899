export const options = {
    Carousel: {
        infinite: false,
    },
    Toolbar: {
        display: {
        left: [
        ],
        middle: [
            "infobar",
        ],
        right: [
            "rotateCW",
            // "iterateZoom",
            "slideshow",
            // "thumbs", 
            "close",
        ],
        },
    },
}