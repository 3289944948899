export const homeTranslation = {
    en: {
        "box_1_title": "My dream is to hack NASA using HTML",
        "box_1_description": "Currently studying at the Vocational Certificate at the Science-Based Technology Vocational College (Chonburi). Information Technology branch.",
        "About Me": "About Me",
        "Dev. Stacks": "Dev. Stacks",
        "Personal Information": "Personal Information",
        "Programming languages": "Programming languages",
        "Frameworks": "Frameworks",
        "Tools": "Tools",
        "IDEs": "IDEs",
        "Etc": "Etc",
        "Live in": "Live in : Samut Prakan, Thailand",
        "Study At": "Study At : Science-Based Technology Vocational College (Chonburi)",
        "Field of Study": "Field of Study : Information Technology (IT)",
        "Hobbies": "Hobbies: Listening to music, watching anime, coding, sleeping",
        "Fav Anime": "Fav Anime : 86, Spy X Family, Bloom into You, YourName ETC.",
    },
    th: {
        "box_1_title": "ความฝันผมคือการเเฮกนาซ่าด้วย HTML",
        "box_1_description": "ตอนนี้กำลังศึกษาอยู่ที่ วิทยาลัยอาชีวศึกษาเทคโนโลยีฐานวิทยาศาสตร์(ชลบุรี) สาขา เทคโนโลยีสารสนเทศ ครับ",
        "About Me": "เกี่ยวกับฉัน",
        "Dev. Stacks": "ความสามารถ",
        "Personal Information": "ข้อมูลส่วนตัว",
        "Programming languages": "ภาษาโปรเเกรม",
        "Frameworks": "เฟรมเวิร์ค",
        "Tools": "เครื่องมือ",
        "IDEs": "โปรเเกรม",
        "Etc": "อื่นๆ",
        "Live in": "อาศัยที่ : จ.สมุทรปราการ ประเทศไทย",
        "Study At": "ศึกษาที่ : วิทยาลัยอาชีวศึกษาเทคโนโลยีฐานวิทยาศาสตร์(ขลบุรี)",
        "Field of Study": "สาขา : เทคโนโลยีสารสนเทศ (IT)",
        "Hobbies": "งานอดิเรก : ฟังเพลง, ดูอนิเมะ, โค้ด, นอน",
        "Fav Anime": "อนิเมะที่ชอบ : เอทตี้ซิกซ์, สปาย X แฟมิลี, สุดท้ายก็คือเธอ, หลับตาฝัน ถึงชื่อเธอ เเละอื่นๆ",
    }
}